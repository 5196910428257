
    import { Component, Vue } from 'vue-property-decorator'
    let dq_1 = require('./../../../assets/imgs/dq_1_new.png'),
        dq_2 = require('./../../../assets/imgs/dq_2_new.png'),
        dq_3 = require('./../../../assets/imgs/dq_3_new.png');
    @Component({
        name: 'DashQuestion'
    })
    export default class DashQuestion extends Vue {
        private questionList: Array<any> = [
            { icon: dq_1, question: 'When can I register? How do I register?', answer: 'After the end of the test, you can use the test results to register and pay through the WeChat Mini Program.' },
            { icon: dq_2, question: 'What subjects does Fangtian offer? Will there be other subjects?', answer: 'Fangtian currently only offers Science subjects, Chinese and English. Fangtian’s principle of doing things is to focus, and we are very cautious about the offering of new subjects. In order to ensure good teaching quality and teaching services, we must be well prepared to open new subjects.' },
            { icon: dq_3, question: 'Can my class type still be promoted after registration?', answer: 'Yes, Fangtian’s hierarchical teaching is to put children who have the relatively close learning levels in the same class. In this way, on the one hand, it is conducive to the teacher’s control of the progress in class, and more importantly, it is for the child to let them learn in the appropriate class, which can help them follow the progress of the class' },
            { icon: dq_3, question: 'Can parents observe?', answer: 'Yes, parents can learn in class with students if they obey the rules of the class.' },
            { icon: dq_1, question: 'What should I do if I am not satisfied after registration?', answer: 'Fangtian supports free transfer and adjustment of classes. Parents can transfer or adjust classes on the Mini Program or the official website, and transfer to their favorite classes. You can also go to the front to ask the front teachers to help with the operation' },
            { icon: dq_2, question: 'Can I get a refund after registration?', answer: 'If the remaining lessons of each semester are not less than three times, the fee can be refunded at any time, and no single lesson can be refunded' }
        ]
    }
