var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"dash-high-score-list"},[_vm._m(0),_c('div',{staticClass:"pos-r dj-content"},[_c('ul',{staticClass:"flex ai-c jc-c fm-step-wrapper"},[_c('li',{staticClass:"mr48px subitem"},[_vm._m(1),_c('div',{staticClass:"step-data"},[_c('a-progress',{staticClass:"step-process",attrs:{"type":"circle","percent":12.5,"stroke-color":"#4993FC","width":158}})],1),_c('div',{staticClass:"step-b-text pad-lr25"},[_vm._v(" There are 153 people with a full score in a single subject, accounting for 12.07% ")]),_vm._m(2)]),_c('li',{staticClass:"mr48px subitem"},[_vm._m(3),_c('div',{staticClass:"step-data"},[_c('a-progress',{staticClass:"step-process2",attrs:{"type":"circle","stroke-color":"#20D152","percent":7.79,"width":158}})],1),_c('div',{staticClass:"step-b-text pad-lr25"},[_vm._v(" There were 117 people with a full score in a single subject, accounting for 30.47% ")]),_vm._m(4)]),_c('li',{staticClass:"subitem"},[_vm._m(5),_c('div',{staticClass:"step-data"},[_c('a-progress',{staticClass:"step-process3",attrs:{"type":"circle","stroke-color":"#FF8341","percent":30.47,"width":158}})],1),_c('div',{staticClass:"step-b-text pad-lr25"},[_vm._v(" There are 153 people with a full score in a single subject, accounting for 12.07% ")]),_c('div',{staticClass:"text-l pad-lr25"})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"tac title"},[_vm._v("Top Students are back! Who can compete with students in Fangtian?")]),_c('div',{staticClass:"pos-r tac sub-title"},[_vm._v("2020 FangTian Single Subject High Score List")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"step-title"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/imgs/ml.png")}})]),_c('span',{staticStyle:{"color":"#4993FC","font-size":"20px"}},[_vm._v("Mathematics")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/imgs/mr.png")}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-l pad-lr25"},[_c('p',[_vm._v("Full marks are 150；")]),_c('p',[_vm._v("222 people’s scores are between 145 to 150 points；")]),_c('p',[_vm._v("156 people’s scores are between 140 to 144 points；")]),_c('p',[_vm._v("Students with a score of 140 points or more in a single subject accounted for 41.88%；")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"step-title"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/imgs/pl.png")}})]),_c('span',{staticStyle:{"color":"#20D152","font-size":"20px"}},[_vm._v("Physics")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/imgs/pr.png")}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-l pad-lr25"},[_c('p',[_vm._v("Full marks are 70；")]),_c('p',[_vm._v("268 people’s scores in Physics are between 65 to 69；")]),_c('p',[_vm._v("95 people’s scores in Physics are between 60 to 64；")]),_c('p',[_vm._v("Students with a score of 60 or more in a single subject accounted for 57.38%；")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"step-title"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/imgs/cl.png")}})]),_c('span',{staticStyle:{"color":"#FF8341","font-size":"20px"}},[_vm._v("Chemistry")]),_c('span',[_c('img',{attrs:{"src":require("@/assets/imgs/cr.png")}})])])
}]

export { render, staticRenderFns }