
    import { Component, Vue, Watch } from 'vue-property-decorator'
    let dy_1 = require('./../../../assets/imgs/dy_1.png'),
        dy_2 = require('./../../../assets/imgs/dy_2.png'),
        dy_3 = require('./../../../assets/imgs/dy_3.png'),
        dy_4 = require('./../../../assets/imgs/dy_4.png'),
        dy_5 = require('./../../../assets/imgs/dy_5.png');
    @Component({
        name: 'DashLayered'
    })
    export default class DashLayered extends Vue {
        private dyContentList: Array<any> = [
            { icon: dy_1, title: 'Foundation', info: 'Focus on laying a solid foundation to ensure that simple questions do not lose points' },
            { icon: dy_2, title: 'Enhancement', info: 'Focus on the re-improvement of the basics' },
            { icon: dy_3, title: 'Creation', info: 'Focus on the learning and exploration of mathematical problems' },
            { icon: dy_4, title: 'Exploration', info: 'Pay attention to the study of the difficulty topics of the national finals' },
            { icon: dy_5, title: 'Fangtian', info: 'Learn and master the types of questions which are as difficult as the questions of National High School Math League, CMO, IMO' }
        ]
    }
