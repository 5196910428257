
    import { Component, Vue, Watch } from 'vue-property-decorator'
    let dl_1 = require('./../../../assets/imgs/dl_1.png'),
        dl_2 = require('./../../../assets/imgs/dl_2.png'),
        dl_3 = require('./../../../assets/imgs/dl_3.png'),
        dl_4 = require('./../../../assets/imgs/dl_4.png'),
        dl_5 = require('./../../../assets/imgs/dl_5.png'),
        dl_6 = require('./../../../assets/imgs/dl_6.png'),
        dl_7 = require('./../../../assets/imgs/dl_7.png');
    @Component({
        name: 'DashLearningeffect'
    })
    export default class DashLearningeffect extends Vue {
        private stepItemList: Array<any> = [
            { icon: dl_1, text: 'Before-class Test', info: 'Use the 20 minutes before the class to review the main points of the previous lesson' },
            { icon: dl_2, text: 'Knowledge Explanation', info: 'Teach the knowledge points of this lesson in the form of example practice and explanations' },
            { icon: dl_3, text: 'Mistakes', info: 'Use recess to explain and correct the wrong questions in this pre-class test' },
            { icon: dl_4, text: 'Class Livestreaming', info: 'The teaching assistant teacher will broadcast the lesson content live in the class group with pictures and word description' },
            { icon: dl_5, text: 'After-Class Test', info: 'Use the 20 minutes after class to practice the main points of knowledge in class' },
            { icon: dl_6, text: 'Effective Homework', info: 'After the class,the knowledge points of this lesson are reinforced and practiced' },
            { icon: dl_7, text: 'Course Summary', info: 'Generate a dedicated grade report for each student through Big Data' },
        ]
    }
