
    import { Component, Vue } from 'vue-property-decorator'

    // import DashHighscorelist from './highScoreList/index.vue'
    import DashSwiper from './swiper/index.vue'
    import DashTeacher from './teacher/index.vue'
    import DashJoingenius from './joinGenius/index.vue'
    import DashEvaluation from './teacherEvaluation/index.vue'
    import DashGoodteaching from './goodTeaching/index.vue'
    import DashLearningeffect from './learningEffect/index.vue'
    import DashLayered from './layered/index.vue'
    import DashResearch from './teachingResearch/index.vue'
    import DashHighscorelist from "@/views/dashboard/highScoreList/index.vue";
    import DashAboutfangtian from './aboutFangtian/index.vue'
    import DashQuestion from './question/index.vue'
    import DashCampusintro from './campusIntro/index.vue'

    @Component({
        name: 'Dashboard',
        components: { DashSwiper, DashTeacher, DashHighscorelist, DashJoingenius, DashEvaluation, DashGoodteaching, DashLearningeffect, DashLayered, DashResearch, DashAboutfangtian, DashQuestion, DashCampusintro }
    })
    export default class Dashboard extends Vue {
      mounted() {
        console.log(process.env.BASE_URL, '返回装固态')
        console.log(this.$router, '返回')
      }
    }
